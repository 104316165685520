<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="certification-add"
  >
    <div>
      <h2 class="mg-y-2">
        <span class="cr-pointer" @click="$router.push(`/certification`)">
          Certification
        </span>
        >
        <span class="color-light-blue">Edit Certificate</span>
      </h2>
      <el-form
        v-if="!loading"
        label-position="top"
        :model="form"
        ref="ruleForm"
        :rules="rules"
        status-icon
      >
        <el-card
          class="custom-card requisition-condition blue active"
          shadow="never"
        >
          <div slot="header" class="clearfix">
            <span>จัดการใบ Certificate</span>
          </div>
          <AddCertification :form="form" :typePage="`edit`" />
        </el-card>
      </el-form>
      <div :gutter="15" class="is-flex ai-center js-end mg-t-4">
        <el-button
          type="warning"
          class="button-radius font-14"
          @click="preview()"
          >Preview</el-button
        >
        <el-button
          type="warning"
          class="button-radius font-14"
          @click="submitSave()"
          :loading="loadingSave"
          >Save</el-button
        >
        <el-button
          type="warning"
          class="button-radius font-14"
          @click="submitPrint()"
          :loading="loadingPrint"
          >Print</el-button
        >
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogPreview"
      width="80%"
      top="30px"
      class="dialog-preview"
      :show-close="false"
    >
      <div v-if="dialogPreview">
        <PreviewCertification :form="form" />
      </div>
    </el-dialog>
    <Html2pdf :generatePdf="generatePdf" @onProgress="onProgress">
      <PreviewCertification :form="form" />
    </Html2pdf>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import AddCertification from "@/components/certification/AddCertification";
import PreviewCertification from "@/components/certification/PreviewCertification";
import Html2pdf from "@/components/pdf/Html2pdf";
import { mapState } from "vuex";

export default {
  components: {
    AddCertification,
    PreviewCertification,
    Html2pdf,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      setCsrf: (state) => state.setCsrf,
    }),
  },

  mounted() {
    this.getCertificationById();
  },
  data() {
    return {
      loading: false,
      loadingPrint: false,
      loadingSave: false,
      form: {},
      rules: {
        companyName: [
          {
            required: true,
            message: "กรุณากรอก Company name",
            trigger: ["blur", "change"],
          },
        ],

        certificateScope: [
          {
            required: true,
            message: "กรุณากรอก Business Location",
            trigger: "blur",
          },
        ],
        businessLocation: [
          {
            required: true,
            message: "กรุณากรอก Business Location",
            trigger: "blur",
          },
        ],
        productionAddress: [
          {
            required: true,
            message: "กรุณากรอก Business Location",
            trigger: "blur",
          },
        ],
        standard: [
          {
            required: true,
            message: "กรุณากรอก Standard",
            trigger: "blur",
          },
        ],
        standardDescription: [
          {
            required: true,
            message: "กรุณากรอกคำอธิบายมาตรฐาน",
            trigger: "blur",
          },
        ],
        registrationNo: [
          {
            required: true,
            message: "กรุณากรอก Registration No.",
            trigger: "blur",
          },
        ],
        firstIssuingDate: [
          {
            required: true,
            message: "กรุณาเลือก First Issuing Date",
            trigger: "change",
          },
        ],
        issuingDate: [
          {
            required: true,
            message: "กรุณาเลือก Issuing Date",
            trigger: "change",
          },
        ],
        expiationDate: [
          {
            required: true,
            message: "กรุณาเลือก Issuing Date",
            trigger: "change",
          },
        ],
        issuedBy: [
          {
            required: true,
            message: "กรุณาเลือก Isued By",
            trigger: "change",
          },
        ],
      },
      dialogPreview: false,
      generatePdf: false,
      progress: 0,
    };
  },
  methods: {
    getCertificationById() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`certification/` + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            let finByIdCer = res.data.obj.certification.find(
              (cer) => cer._id.toString() == this.$route.params.id
            );
            let cer = null;
            if (finByIdCer != undefined) {
              cer = finByIdCer;
            }
            let setFile = [];
            if (cer.logo1 != "") {
              setFile.push({ typelogo: 1, name: cer.logo1 });
            }
            if (cer.logo2 != "") {
              setFile.push({ typelogo: 2, name: cer.logo2 });
            }
            if (cer.logo3 != "") {
              setFile.push({ typelogo: 3, name: cer.logo3 });
            }

            this.form = {
              ...cer,
              companyName: res.data.obj.companyName,
              registerAddress: res.data.obj.address,
              companyType: res.data.obj.companyType,
              certificateScope: cer.certificateScope,
              fileLogo: setFile,
              delFiles: [],
              _id: res.data.obj._id,
            };
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitPrint() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loadingPrint = true;
          setTimeout(() => {
            this.generatePdf = true;
          }, 100);
        }
      });
    },
    async submitSave() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loadingSave = true;

          let obj = {
            certificationId: this.form.certificationId,
            businessLocation: this.form.businessLocation,
            productionAddress: this.form.productionAddress,
            standard: this.form.standard,
            standardDescription: this.form.standardDescription,
            registrationNo: this.form.registrationNo,
            firstIssuingDate: this.form.firstIssuingDate,
            issuingDate: this.form.issuingDate,
            expiationDate: this.form.expiationDate,
            alteringDate: this.form.alteringDate,
            issuedBy: this.form.issuedBy,
            delFiles: this.form.delFiles,
            _id: this.form._id,
            certificateScope: this.form.certificateScope,
            desLogo1: this.form.desLogo1,
            desLogo2: this.form.desLogo2,
            desLogo3: this.form.desLogo3,
          };

          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          HTTP.post(`certification/edit/` + this.$route.params.id, obj)
            .then(async (res) => {
              if (res.data.success) {
                let arrLogo = this.form.fileLogo.reduce((result, row) => {
                  if (row.file != undefined) {
                    result.push({ ...row });
                  }
                  return result;
                }, []);
                if (arrLogo.length > 0) {
                  await this.uploadFiles(arrLogo);
                }

                this.$notify({
                  title: "แก้ไข Certificate สำเร็จ!",
                  type: "success",
                  customClass: "success",
                });
                this.$router.push(`/certification`);
              }
            })
            .catch((e) => {
              if (typeof e.response != "undefined") {
                if (e.response.data.status == 401) {
                  this.checkAuth();
                }
              }
            })
            .finally(() => {
              this.loading = false;
              this.loadingSave = false;
            });
        }
      });
    },
    preview() {
      this.dialogPreview = true;
    },
    onProgress(progress) {
      this.progress = progress;
      if (this.progress == 100) {
        this.loadingPrint = false;
        this.generatePdf = false;
      }
    },

    async uploadFiles(data) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.setCsrf,
        },
      };

      var formData = new FormData();

      formData.append("_csrf", this.setCsrf);
      if (data.length > 0) {
        formData.append("certificationId", this.form._id);
        formData.append("refId", this.$route.params.id);
        data.forEach((e) => {
          formData.append("filesUpload", e.file.raw);
          formData.append("typelogo", e.typelogo);
          formData.append("filesName", e.file.name);
        });
      }

      await HTTP.post(`certification/files`, formData, config)
        .then(async (res) => {
          if (res.data.success) {
            return true;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
  },
};
</script>
